<template>
  <div class="home-box">
    <div class="w">
      <div class="box">
        <div class="wl-title3">
          <!-- 使用您的PSC -->
          {{ $t("use.pscBox2T") }}
        </div>
        <div class="p">
          <!-- 现在，您已经持有了一些PSC。您可以查看一些PSC网络应用（去中心化应用）。有很多类型的去中心化应用，包括金融、社交、媒体、游戏，还有其他各种领域。 -->
          {{ $t("use.pscBox2P") }}
        </div>
        <a href="javascript:;" @click="uti.goPath(store.link.use.box2But)">
          <div class="but">
            <!-- 查看去中心化应用 -->
            {{ $t("use.pscBox2But") }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // height: 1213.1px;
  // background: #f4faff, url("../../assets/images/v4/home/box2Box.png");
  background-color: #f4faff;
  background-image: url("../../assets/images/v4/home/box2Bg.png");
  padding-top: 112px;
  padding-bottom: 112px;
  margin-bottom: 250px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .box {
    height: 620px;
    background-image: url("../../assets/images/v4/use/getBox2.png");
    border-radius: 20px;
    position: relative;
    padding-left: 92px;
    padding-top: 66px;
    box-sizing: border-box;
    // display: none;
    .t {
      width: 381px;
      height: 56px;
      font-size: 60px;
      font-weight: 500;
      position: absolute;
      left: 103px;
      top: 112px;
      color: #333333;
    }
    .p {
      width: 581px;
      font-size: 20px;
      line-height: 33px;
      font-weight: 500;
      color: #999999;
      position: absolute;
      left: 103px;
      top: 224px;
    }
    .but {
      text-align: center;
      line-height: 54px;
      color: #fff;
      height: 54px;
      padding: 0 36px;
      background: linear-gradient(-90deg, #10c1fc, #3f65f9);
      box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
      border-radius: 27px;
      position: absolute;
      left: 103px;
      top: 420px;
    }
  }
}
</style>
