<template>
  <div class="wl-use-getpsc">
    <getpsc-box-1></getpsc-box-1>
    <getpsc-box-2></getpsc-box-2>
  </div>
</template>
<script>
import GetpscBox1 from "./getpsc-box1.vue";
import GetpscBox2 from "./getpsc-box2.vue";
export default {
  components: { GetpscBox1, GetpscBox2 },
  data() {
    return {};
  },
};
</script>
