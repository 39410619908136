<template>
  <div class="dev-item">
    <div class="w">
      <div class="t">
        <!-- 在哪里购买PSC -->
        {{ $t("use.pscBox1T") }}
      </div>
      <div class="p">
        <!-- 您可以通过交易所或者钱包应用直接购买PSC。搜索您的所在地区有哪些可用服务。 -->
        {{ $t("use.pscBox1P1") }}
        <a href="javascript:;" @click="uti.goPath(store.link.use.swap)">
          <span class="lan">
            {{ $t("use.pscBox1P2") }}
          </span>
        </a>
        {{ $t("use.pscBox1P3") }}
      </div>
      <div class="box">
        <div class="top">
          <!-- 当前PSC价格 (美元) -->
          {{ $t("use.pscBox1BoxT") }}
        </div>
        <div class="bigNum">${{ homePrice }}</div>
        <div class="bot" v-if="false">
          <div class="span">
            4.65%<svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-youshang01"></use>
            </svg>
          </div>
          <div class="mini">
            (
            <!-- 最近24小时 -->
            {{ $t("use.pscBox1BoxB") }}
            )
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      homePrice:localStorage.getItem("homePrice")
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  height: 100vh;
  background: linear-gradient(0deg, #dae6f2, #edf4fc);
  .w {
    // background-color: pink;
    height: 100vh;
    background-image: url("../../assets/images/v4/use/getBox1BG.png");
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 200px;
    box-sizing: border-box;
    text-align: center;
    .t {
      // height: 66px;
      font-size: 70px;
      font-weight: bold;
      color: #333333;
    }
    .p {
      width: 511px;
      height: 66px;
      font-size: 24px;
      font-weight: 500;
      color: #666666;
      margin: 0 auto;
      margin-top: 39px;
    }
    .box {
      margin: 0 auto;
      width: 520px;
      // height: 250px;
      padding-bottom: 50px;
      margin-top: 74px;
      background: linear-gradient(180deg, #2164fc, #40c3f9);
      border-radius: 10px;
      padding-top: 34px;
      box-sizing: border-box;
      .top {
        height: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
      .bigNum {
        height: 58px;
        font-size: 60px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 34px;
      }
      .bot {
        width: 100%;
        font-size: 30px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-top: 44px;
        justify-content: space-around;
        // justify-content: center;
        .icon {
          font-size: 20px;
        }
        .mini {
          display: inline-block;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
